import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { isEqual } from 'lodash';
import { IOneToOneDahboardProps } from './IOneToOneDashboardProps';
import GaugeComponent from 'react-gauge-component';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { StyledSearchableDropdownProps, Dropdown, DropdownOption } from '@ui-components/ui-library';
import TuneIcon from '@mui/icons-material/Tune';
import FilterIcon from '@mui/icons-material/FilterAltOffOutlined';
import { useTranslation } from 'react-i18next';
import { FilterModal } from '../../../components/filterModal/FilterModal';
import { OneToOneFilterModel, getDefaultOneToOneFilterModel } from '../../models/OneToOneFilterModel';
import { EquipmentType } from '../../../equipments/models/equipmentType';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { BrandViewModel } from '../../../models/brand/brandModel';
import { CountryFormModel } from '../../../organizations/models/countryModel';
import { InstalledBaseViewModel } from '../../../models/installedBase/installedBaseViewModel';
import { OrganizationOptionModel } from '../../../organizations/models/organizationModel';
import { ALL_TEXT, KPI_GAUGE_COLORS, NO_OPTIONS_TEXT } from '../../../shared/utils/constants';
import { BrandTypeViewModel } from '../../../models/brand/brandTypeModel';
import { DatePicker } from '../../../components/form/datePicker/DatePicker';
import { AuthenticatedUser } from '../../../users/models/user';
import { Restricted } from '../../../components/elements/restricted/Restricted';
import { OrganizationType } from '../../../shared/enums/OrganizationType';
import { EquipmentCategories } from '../../../shared/enums/EquipmentCategories';
import { DropdownListItemLongContentSxObject } from '../../../shared/utils/sxStyleObjects';

export const OneToOneDashboard = (props: IOneToOneDahboardProps): JSX.Element => {
    const { t } = useTranslation();

    const currentUser: AuthenticatedUser = useAppSelector(state => state.User.currentUser);

    const { oneToOneKpi, applyOneToOneFilters } = props;

    const equipmentTypeOptions: EquipmentType[] = useAppSelector(state => state.Dashboard.equipmentTypeOptions);
    const brandOptions: BrandViewModel[] = useAppSelector(state => state.Dashboard.brandOptions);
    const brandTypeOptions: BrandTypeViewModel[] = useAppSelector(state => state.Dashboard.brandTypeOptions);
    const countryOptions: CountryFormModel[] = useAppSelector(state => state.Dashboard.countryOptions);
    const installedBaseOptions: InstalledBaseViewModel[] = useAppSelector(state => state.Dashboard.installedBaseOptions);
    const organizationOptions: OrganizationOptionModel[] = useAppSelector(state => state.Dashboard.organizationOptions);

    const [filterModalIsOpen, setFilterModalIsOpen] = useState<boolean>(false);
    const [appliedFilters, setAppliedFilters] = useState<OneToOneFilterModel>(getDefaultOneToOneFilterModel());
    const [notAppliedFilters, setNotAppliedFilters] = useState<any>({});

    const closeFilterModal = () => {
        setFilterModalIsOpen(false);
        setNotAppliedFilters({});
    };

    const handleFilterChange = (propName: keyof OneToOneFilterModel, value: any): void => {
        setNotAppliedFilters({ ...notAppliedFilters, [propName]: value });
    };

    const applyFilters = async (): Promise<void> => {
        await applyOneToOneFilters({ ...appliedFilters, ...notAppliedFilters });
        setAppliedFilters({ ...appliedFilters, ...notAppliedFilters });
        closeFilterModal();
    };

    const clearFilters = async () => {
        await applyOneToOneFilters(getDefaultOneToOneFilterModel());
        setAppliedFilters(getDefaultOneToOneFilterModel());
    };

    const applyButtonIsDisabled = (): boolean => {
        return Object.keys(notAppliedFilters).length === 0 || !Object.keys(notAppliedFilters).some(naf =>
            appliedFilters[naf as keyof typeof appliedFilters] instanceof Date ?
                !(moment(appliedFilters[naf as keyof typeof appliedFilters]).isSame(notAppliedFilters[naf], 'day')) :
                !isEqual(appliedFilters[naf as keyof typeof appliedFilters], notAppliedFilters[naf]));
    };

    const getSelectedDropdownOption = (propName: keyof OneToOneFilterModel, optionsCollection: any[]): DropdownOption | null | undefined => {
        if ((!appliedFilters[propName as keyof typeof appliedFilters] && !notAppliedFilters[propName as keyof typeof notAppliedFilters]) ||
            (propName in notAppliedFilters && notAppliedFilters[propName as keyof typeof notAppliedFilters] === undefined)) {
            return null;
        }

        if (notAppliedFilters[propName as keyof typeof notAppliedFilters]) {
            return optionsCollection.find(et => et.id === notAppliedFilters[propName as keyof typeof notAppliedFilters]);
        } else if (appliedFilters[propName as keyof typeof appliedFilters]) {
            return optionsCollection.find(o => o.id === appliedFilters[propName as keyof typeof appliedFilters]);
        }
    };

    const optionalFilters = (({ equipmentTypeId, brandId, brandTypeId, organizationId, countryId, installedBaseId }) => ({ equipmentTypeId, brandId, brandTypeId, organizationId, countryId, installedBaseId }))(appliedFilters);
    const hasAppliedFilters: boolean = Object.keys(optionalFilters).some(of => appliedFilters[of as keyof typeof appliedFilters]) ||
        !moment(appliedFilters.lastMaintenanceDateFrom).isSame(moment().add(-1, 'y'), 'day') || !moment(appliedFilters.lastMaintenanceDateTo).isSame(moment(), 'day');

    const calculateOneToOnePercent = (): number => {
        if (oneToOneKpi.totalNumberOfEquipment !== 0) {
            return (oneToOneKpi.servicedEquipment / oneToOneKpi.totalNumberOfEquipment) * 100;
        }

        return 0;
    };

    const equipmentTypeDropdownOptions = useMemo(() => equipmentTypeOptions.filter(e => e.equipmentCategory === EquipmentCategories.Compressor).map(et => ({ id: et.id, label: t(et.description), value: et.id })), [equipmentTypeOptions]);
    const brandTypeDropdownOptions = useMemo(() => brandTypeOptions.map(b => ({ id: b.id, label: b.description, value: b.id })), [brandTypeOptions]);
    const brandDropdownOptions = useMemo(() => brandOptions.map(b => ({ id: b.id, label: b.name, value: b.id })), [brandOptions]);
    const organizationsDropdownOptions = useMemo(() => organizationOptions.map(o => ({ id: o.id, label: o.organizationType == OrganizationType.Distributor ? o.name : o.famCode, value: o.id })), [organizationOptions]);
    const countryDropdownOptions = useMemo(() => countryOptions.map(c => ({ id: c.id, label: c.description, value: c.id })), [countryOptions]);
    const installedBaseDropdownOptions = useMemo(() => installedBaseOptions.map(ib => ({ id: ib.id, label: ib.description, value: ib.id })), [installedBaseOptions]);

    return (
        <>
            <Box>
                <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box fontSize={18} flex={2} textAlign={'left'} dir={'ltr'}>{t('1 to 1 KPI')} ({oneToOneKpi.servicedEquipment} / {oneToOneKpi.totalNumberOfEquipment})</Box>

                    <Tooltip
                        title={t('Filter')}
                    >
                        <IconButton id={'filter'} onClick={() => setFilterModalIsOpen(true)}>
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>

                    {
                        hasAppliedFilters &&
                        <Tooltip
                            title={t('Clear filters')}
                        >
                            <IconButton id={'clear_filters'} onClick={clearFilters}>
                                <FilterIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>

                <Box dir='ltr'>
                    <GaugeComponent
                        value={calculateOneToOnePercent()}
                        type={'semicircle'}
                        arc={{
                            colorArray: KPI_GAUGE_COLORS,
                            nbSubArcs: 25,
                            padding: 0.02,
                            width: 0.2,

                        }}
                        pointer={{
                            elastic: true,
                            animationDelay: 0
                        }}
                    />
                </Box>
            </Box>

            <FilterModal
                isOpen={filterModalIsOpen}
                disableApply={applyButtonIsDisabled()}
                onClose={closeFilterModal}
                onApply={applyFilters}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'lmd_from'}
                            inputFormat={currentUser.preferences.dateFormat}
                            onChange={value => value ? handleFilterChange('lastMaintenanceDateFrom', moment(value).startOf('d').toDate()) : undefined}
                            value={notAppliedFilters['lastMaintenanceDateFrom'] || appliedFilters['lastMaintenanceDateFrom']}
                            disableFuture
                            actions={['today']}
                            title={t('From')}
                            maxDate={notAppliedFilters['lastMaintenanceDateTo'] || appliedFilters['lastMaintenanceDateTo']}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <DatePicker
                            id={'lmd_to'}
                            inputFormat={currentUser.preferences.dateFormat}
                            onChange={value => value ? handleFilterChange('lastMaintenanceDateTo', moment(value).endOf('d').toDate()) : undefined}
                            value={notAppliedFilters['lastMaintenanceDateTo'] || appliedFilters['lastMaintenanceDateTo']}
                            disableFuture
                            actions={['today']}
                            title={t('To')}
                            minDate={notAppliedFilters['lastMaintenanceDateFrom'] || appliedFilters['lastMaintenanceDateFrom']}
                        />
                    </Grid>

                    <Restricted to={'canFilterByEquipmentType'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Equipment Type')}
                                options={equipmentTypeDropdownOptions}
                                value={getSelectedDropdownOption('equipmentTypeId', equipmentTypeDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('equipmentTypeId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={equipmentTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByBrandType'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand Type')}
                                options={brandTypeDropdownOptions}
                                value={getSelectedDropdownOption('brandTypeId', brandTypeDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('brandTypeId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandTypeDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByBrand'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Brand')}
                                options={brandDropdownOptions}
                                value={getSelectedDropdownOption('brandId', brandDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('brandId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={brandDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>


                    <Restricted to={'canFilterByOrganization'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Organization')}
                                options={organizationsDropdownOptions}
                                value={getSelectedDropdownOption('organizationId', organizationsDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('organizationId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={organizationsDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                optionListStyles={DropdownListItemLongContentSxObject}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByCountry'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Country')}
                                options={countryDropdownOptions}
                                value={getSelectedDropdownOption('countryId', countryDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('countryId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={countryDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                    <Restricted to={'canFilterByInstalledBaseCode'}>
                        <Grid item xs={12} md={6}>
                            <Dropdown<StyledSearchableDropdownProps>
                                label={t('Installed Base Code')}
                                options={installedBaseDropdownOptions}
                                value={getSelectedDropdownOption('installedBaseId', installedBaseDropdownOptions)}
                                onChange={(e, item) => handleFilterChange('installedBaseId', item ? Number((item as DropdownOption).value) : undefined)}
                                variant='searchable'
                                usePopper
                                disabled={installedBaseDropdownOptions.length === 0}
                                noOptionsText={t(NO_OPTIONS_TEXT)}
                                placeholder={t(ALL_TEXT)}
                            />
                        </Grid>
                    </Restricted>

                </Grid>
            </FilterModal>
        </>
    );
};
